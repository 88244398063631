import React from 'react';
import styled from './index.module.css';
import { Link } from 'gatsby';
import BlogPostImageThumbs from '../BlogPostImageThumbs';

const BlogPostPreviewStyling = ({ edge, idx }) => {
  return (
    <div className={styled.wrapper}>
      <div className={styled[`margin${(idx % 2) + 1}`]}>
        {edge ? (
          <div className={styled.grid}>
            <BlogPostImageThumbs
              styling={styled.image}
              image={edge.node.frontmatter.image}
              title={edge.node.frontmatter.title}
              idx={idx}
            />
            <h2 className={styled.title}>{edge.node.frontmatter.title}</h2>
            <div className={styled.linkWrapper}>
              <Link className={styled.link} to={`/blog/${edge.node.fields.slug}`}>
                Read More
              </Link>
            </div>
            <p className={styled.body}>{edge.node.frontmatter.pitch}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default BlogPostPreviewStyling;
