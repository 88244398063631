import React from 'react';
import styled from './index.module.css';
import ImageFeatProductCircle from '../../../images/imageComponents/ImageFeatProductCircle';
import ImageFeatProductRectangle from '../../../images/imageComponents/ImageFeatProductRectangle';
import ImageFeatProductLine from '../../../images/imageComponents/ImageFeatProductLine';
import img from 'gatsby-image';

const BlogPostImageThumb = ({ image, title, idx }) => {
  const PositionInRoll = (idx % 4) + 1;

  return (
    <div key={`blogThumbsnr${PositionInRoll}`} className={styled[`floatAndShape${PositionInRoll}`]}>
      <div className={styled.wrapper}>
        <div className={styled.thumbnailGraphicComposition}>
          <img className={styled[`blogThumbnail${PositionInRoll}`]} loading="lazy" fixed={image} alt={title} />
          {PositionInRoll === 1 ? (
            <>
              <ImageFeatProductCircle styled={styled.blogThumbnail1Circle} />
              <ImageFeatProductLine styled={styled.blogThumbnail1Line} />
            </>
          ) : PositionInRoll === 2 ? (
            <ImageFeatProductRectangle styled={styled.blogThumbnail2Rectangle} />
          ) : PositionInRoll === 3 ? (
            <div className={styled.blogThumbnail3Circle}>
              <ImageFeatProductCircle />
            </div>
          ) : PositionInRoll === 4 ? (
            <div className={styled.blogThumbnail4Line}>
              <ImageFeatProductLine />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default BlogPostImageThumb;
