import React from 'react';

const featproductRectangle = (props) => {
  return (
    <svg className={props.styled || ''} viewBox="0 0 312 312" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="310" height="310" rx="9.5" stroke="#382E26" />
    </svg>
  );
};

export default featproductRectangle;
