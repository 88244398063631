import React from 'react';

const featproductRectangle = (props) => {
  return (
    <svg
      className={props.styled || ''}
      viewBox="0 0 164 164"
      stroke="black"
      strokeWidth=".5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="80" y2="150" />
    </svg>
  );
};

export default featproductRectangle;
